.explore {
  position: relative;
  overflow: hidden;
  padding-bottom: 2em;
}

.split {
  display: flex;
  gap: 2em;
  justify-content: space-evenly;
}

.split > div {
  max-width: calc(50% - 4em);
}

.explore ul {
  margin: 0;
  padding: 0;
  max-width: 360px;
  min-width: 220px;
  flex: 1 1;
}

.explore li {
  list-style-type: none;
  padding-bottom: .5em;
}

.explore li > div {
  display: flex;
}
.explore li h4 {
  margin: .7em 0 1em .8em;
  line-height: 1.2em;
  font-size: 1.2em;
  flex: 1;
}
.explore li p {
  margin: 0 0 1em calc(48px + 1em);
  line-height: 1.5em;
}

.explorebutton, .header {
  display: none;
}

.explore h3 {
  font-weight: 200;
  text-align: center;
  font-size: 2.5em;
  margin: 1.5em 0 1.5em;
}

.explore h3:after {
  content: "";
  display: block;
  margin: 25px auto;
  border-top: 2px solid #e3e3e3;
  width: 180px;
}


.exploreembed iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.wrapper {
  width: 420px;
  height: calc(100vh - 125px);
  max-height: 960px;
  border-radius: .5em;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,.2);
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.loading {
  background-image: url('/images/loader.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.exploreembed.loading .container {
  visibility: hidden;
}

.maintenance {
  display: flex;
  text-align: center;
  padding: 2em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #f4f4f4;
}

.ownersTab {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 1em;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, #fff 4px);
}

.ownersTab > .owners > div {
  padding: 0 1em 1em 2.4em;
  display: none;
}

.ownersTab .bullion.open + .owners > div {
  display: flex;
}


.ownersTab .bullion {
  padding: 1em 0 1em 1em;
  border-top: 1px solid #ddd;
  cursor: pointer;
}

.ownersTab .bullion.open {
  padding: 1em 0 0 1em;
  margin-bottom: 1em;
}

.ownersTab .bullion:hover {
  background: #F5F5F5;
}

.ownersTab .bullion:before {
  content: '';
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23888888" d="M190.06 414l163.12-139.78a24 24 0 000-36.44L190.06 98c-15.57-13.34-39.62-2.28-39.62 18.22v279.6c0 20.5 24.05 31.56 39.62 18.18z"></path></svg>');
  width: 24px;
  height: 24px;
  margin: .5em .2em 0 -.5em;
  float: left;
  transform: rotate(0deg);
  transition: transform .15s ease-out;
}

.ownersTab .bullion.open:before {
  transform: rotate(90deg);
}

.owners:last-child {
  border-bottom: 1px solid #ddd;
}


.ownersTab .goldbar {
  float: left;
  margin: 0 .9em 0 0;
  height: 42px;
  filter: saturate(1.2) contrast(1.1);
  border-radius: .4em;
  box-shadow: 1px 1px 1px rgba(0,0,0,.3);
}

.ownersTab .silverbar {
  float: left;
  margin: 0 .9em 0 0;
  height: 42px;
  filter: saturate(.1) brightness(.9) contrast(1.1);
  border-radius: .4em;
  box-shadow: 1px 1px 1px rgba(0,0,0,.3);
}

.ownersTab .subtext {
  font-size: .85em;
  color: #777;
  margin-top: .5em;
  margin-left: 1.6em;
}

.ownersTab .bullion.open .subtext {
  padding-bottom: 1.18em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ownersTab .starid {
  color: #777;
  flex: 1;
}


.tabs {
  flex: 1;
  position: relative;
}

.backingTab, .ownersTab {
  display: none;
}

.tabs.backing .backingTab {
  display: block;
}

.tabs.owners .ownersTab {
  display: block;
}


.options {
  display: flex;
  justify-content: space-evenly;
  background: #fff;
}

.options hr  {
  flex: 0;
  margin: 0;
  border: 0;
}

.options button {
  border: none !important;
  border-radius: .3em .3em 0 0 !important;
  border-bottom: 1px solid #ddd !important;
  box-shadow: none;
  background: none;
  padding: .8em .65em calc(.75em + 1px);
  margin: 0;
  flex: 1;
  color: #777 !important;
  transition: none;
  font-weight: normal;
}

.options button:hover,
.options button.active,
.options button:active,
.options button:focus {
  background-color: transparent;
  border-bottom: 2px solid #ccc !important;
  color: #222 !important;
  padding-bottom: .75em;
  box-shadow: none !important;
  }

.options button.active,
.options button:active {
  border-bottom-color: #bca36c !important;
  font-weight: bold;
}

.options button:active {
  background-color: rgba(0, 0, 0, .04);
}

.options button:focus {
  border-bottom: 0px !important;
  box-shadow: inset 0 0 0 2px #837652 !important;
  padding-bottom: calc(.75em + 2px);
}




@media (max-width: 900px) {
  .explorebutton {
    display: block;
    margin-top: 1em;
  }

  .explore h3 {
    font-size: 1.8em;
  }

  .split > div {
    max-width: calc(100% - 2em);
  }

  .split > .exploreembed {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    max-width: unset;
    transform: translateY(100vh);
    transition: transform .2s ease-out;
  }

  .header {
    position: relative;
    z-index: 9;
    background: rgba(0,0,0,0);
    display: block;
    width: 100vw;
    height: calc(65px + 1em);
    margin-bottom: -1em;
    transition: all .5s .1s ease-out;
  }

  .header:after {
    content: "";
    position: absolute;
    width: 100px;
    border-radius: 2px;
    height: 4px;
    bottom: calc(5px + 1em);
    left: 50%;
    margin: 0 -50px;
    background: rgba(255,255,255,.5);
  }

  .visible .header {
    background: rgba(0,0,0,.4);
    color: rgba(56,59,67,.94)
  }

  .exploreembed iframe {
    width: calc(100vw + 1px);
  }

  .wrapper {
    position: relative;
    z-index: 10;
    width: 100vw;
    height: calc(100% - 65px);
    border-radius: 1em 1em 0 0;
    background: #fff;
  }

  .visible .exploreembed {
    transform: translateY(0vh);
  }
}
